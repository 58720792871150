import React from "react";
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to Abbydale Jewellery.</h1>
        <p>
          Abbydale Jewellery offers a wide variety of exquisite jewelry for
          every occasion.
        </p>
        <p>
          Our collection includes rings, necklaces, bracelets, and more, crafted
          with the finest materials.
        </p>
        <p>
          Stay tuned for our full website launch, where you can browse and
          purchase our exclusive designs.
        </p>
        <p>Contact us: abbydalejewellery@gmail.com</p>
      </header>
    </div>
  );
}

export default App;
